/* Styleguide-specific css... NOT to be exported */

@import './fonts';

#ba-logo {
  background-image: url('/styleguide/styles/images/BA_Mark_RGB.png');
  background-size: contain;
  height: 33px;
  width: 33px;
}
