/* used by the BALogo component! */

.cls-1 {
  fill:none;
}
.cls-2 {
  clip-path:url(#clip-path);
}
.cls-3 {
  fill:#333845;
}
.cls-4 {
  fill:#70747d;
}
.cls-5 {
  fill:#79b829;
}
.cls-6 {
  fill:#a1cd69;
}
.cls-7 {
  fill:#4283d1;
}
.cls-8 {
  fill:#7ba8df;
}
.cls-9 {
  fill:#e9514f;
}
.cls-10 {
  fill:#f08584;
}
.cls-11 {
  fill:#836d9c;
}
.cls-12 {
  fill:#a899ba;
}

.cls-13 {
  fill: var(--white);
}