.PanelIcon {
  > i > svg {
    vertical-align: -0.125em;
    transition: transform 0.2s;
    transform: rotate(0deg);
  }
  &Active {
    > i > svg {
      transform: rotate(90deg);
    }
  }
}
.PanelIcon {
  > i.fa-chevron-right {
    color: var(--text-main);
    transform-origin: center;
    transition: transform 0.2s;
    transform: rotate(0deg);
  }
  &Active {
    > i.fa-chevron-right {
      transform: rotate(90deg);
    }
  }
}