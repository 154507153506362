// from BA_V3 -- not best sure how to share…
:root {
  // brand
  --brand-1: hsl(221, 16%, 23%);
  --brand-1-light: hsl(221, 16%, 15%);

  --brand-2: hsl(213, 61%, 54%);
  --brand-3: hsl(1, 78%, 61%);
  --brand-4: hsl(268, 29%, 40%);
  --brand-5: hsl(86, 64%, 44%);
  --brand-6: hsl(52, 100%, 61%);

  --opaque: rgba(0, 0, 0, 0);

  --focus-ring-color: hsl(206, 73%, 59%);

  // -- UI -------------------------------
  // UI colours are here to draw attention to important things, particularly actions within the application.

  --white: #ffffff;
  --black: #000000;
  --shadow: rgba(0,0,0,0.3);

  --highlight-lightest: hsl(206, 42%, 84%);
  --highlight-lighter: hsl(206, 73%, 79%);
  --highlight-light: hsl(206, 73%, 69%);
  --highlight: hsl(206, 73%, 59%);
  --highlight-dark: hsl(206, 73%, 49%);
  --highlight-darker: hsl(206, 73%, 39%);

  --confirm-lighter: hsl(150, 25%, 72%);
  --confirm-light: hsl(150, 25%, 62%);
  --confirm: hsl(150, 25%, 52%);
  --confirm-dark: hsl(150, 25%, 42%);
  --confirm-darker: hsl(150, 25%, 32%);

  --warning-lighter: hsl(35, 84%, 77%);
  --warning-light: hsl(35, 84%, 67%);
  --warning: hsl(35, 84%, 57%);
  --warning-dark: hsl(35, 84%, 47%);
  --warning-darker: hsl(35, 84%, 37%);

  --hazard-lighter: hsl(350, 61%, 70%);
  --hazard-light: hsl(350, 61%, 60%);
  --hazard: hsl(350, 61%, 50%);
  --hazard-dark: hsl(350, 61%, 40%);
  --hazard-darker: hsl(350, 61%, 30%);

  // -- TEXT ------------------------------
  --text-main: hsl(206, 60%, 33%);
  --text-main-dark: hsl(206, 60%, 23%);
  --text-main-darker: hsl(206, 60%, 13%);
  --text-dark: var(--text-main-darker);
  --text-light: hsl(228, 11%, 54%);
  --text-dark-bg: hsl(0, 0%, 96%);

  --text-grey: hsl(0, 0%, 45.9%);
  --border-light-grey-darker: hsl(0, 0%, 80%);
  --border-light-grey: hsl(0, 0%, 94%);

  --icon-grey: hsl(0, 0%, 52%);
  --icon-dark-grey: hsl(0, 0%, 42%);

  // -- THEME -----------------------------
  --line: hsl(200, 13%, 95%);
  --line-dark: hsl(200, 13%, 85%);
  --line-darker: hsl(200, 13%, 75%);
  --line-light-grey: hsla(360, 0%, 80%, 1);

  --bg: hsl(90, 9%, 96%);
  --content-bg: white;

  --dark-bg: hsl(223, 7%, 19%);
  --dark-bg-light: hsl(223, 7%, 29%);
  --dark-bg-trans: hsla(223, 7%, 19%, 0.6);

  // Client Settings Colours Defaults
  // (get overwritten by user-chosen colours in the dashboard app on startup)
  --header-font-color: var(--text-dark-bg);
  --header-color: var(--brand-1);
  --header-highlight: var(--brand-1-light);

  --menu-background-color: var(--brand-1);
  --menu-background-color-highlight: var(--header-highlight);
  --menu-link-color: var(--dark-bg-light);
  --menu-text-color: var(--header-font-color);
  --sub-node-color: rgba(0, 0, 0, 0.2);

  --table-color: var(--bg);
  --table-highlight-color: #bfc0be; // var(--bg) darkened by 0.2
  --table-text-color: var(--text-dark);
  --positive-arrow-color: var(--confirm);
  --negative-arrow-color: var(--hazard);
  --neutral-arrow-color: var(--warning);
}

// a sassy loop to generate each of the variants of each standard color
$themeColors: highlight, confirm, warning, hazard;
$variants: light, lighter, dark, darker;
@each $themeColor in $themeColors {
  .bg-#{$themeColor}.bg-#{$themeColor} {
    background-color: var(--#{$themeColor});
  }
  .border-#{$themeColor}.border-#{$themeColor} {
    border-color: var(--#{$themeColor});
  }
  .text-#{$themeColor}.text-#{$themeColor} {
    color: var(--#{$themeColor});
  }
  @each $variant in $variants {
    .bg-#{$themeColor}-#{$variant}.bg-#{$themeColor}-#{$variant} {
      background: var(--#{$themeColor}-#{$variant});
    }
    .border-#{$themeColor}-#{$variant}.border-#{$themeColor}-#{$variant} {
      border-color: var(--#{$themeColor}-#{$variant});
    }
    .text-#{$themeColor}-#{$variant}.text-#{$themeColor}-#{$variant} {
      color: var(--#{$themeColor}-#{$variant});
    }
  }
}

$otherColors: text-main, text-dark, text-light, text-dark-bg, line, bg, dark-bg,
  brand-1, brand-2, brand-3, brand-4, brand-5, brand-6;
@each $col in $otherColors {
  .border-#{$col}.border-#{$col} {
    border-color: var(--#{$col});
  }
  .text-#{$col}.text-#{$col} {
    color: var(--#{$col});
  }
  .bg-#{$col}.bg-#{$col} {
    background: var(--#{$col});
  }
}
