
.list-style-none {
  list-style: none;
  padding:    0;
  margin:     0;
}

.hidden { display:none; }
.invisible { opacity: 0;}
.disabled{ color: #555555; }


// MARGINS / PADDING -----------------------------------------------------------

// padding 0
.padding-none        { padding: 0; }
.padding-top         { padding-top: 0; }
.padding-bottom      { padding-bottom: 0; }
.padding-left        { padding-left: 0; }
.padding-right       { padding-right: 0; }

// padding 0.5rem
.padding-half        { padding: 0.4rem 0.5rem; } // vertical, then horizontal
.padding-half-top    { padding-top: 0.4rem; }
.padding-half-bottom { padding-bottom: 0.4rem; }
.padding-half-left   { padding-left: 0.5rem; }
.padding-half-right  { padding-right: 0.5rem; }

// padding 1rem
.pad                 { padding: 0.8rem 1rem; }
.padding             { padding: 0.8rem 1rem; }
.padding-top         { padding-top: 0.8rem; }
.padding-bottom      { padding-bottom: 0.8rem; }
.padding-left        { padding-left: 1rem; }
.padding-right       { padding-right: 1rem; }

// padding 2rem
.padding-two         { padding: 2rem; }
.padding-two-top     { padding-top: 2rem; }
.padding-two-right   { padding-right: 2rem; }
.padding-two-bottom  { padding-bottom: 2rem; }
.padding-two-left    { padding-left: 2rem; }


// margin 0
.margin-none        { margin: 0; }
.margin-top         { margin-top: 0; }
.margin-bottom      { margin-bottom: 0; }
.margin-left        { margin-left: 0; }
.margin-right       { margin-right: 0; }

// margin 0.5rem
.margin-half         { margin: 0.4rem 0.5rem; }
.margin-half-top     { margin-top: 0.4rem; }
.margin-half-bottom  { margin-bottom: 0.4rem; }
.margin-half-left    { margin-left: 0.5rem; }
.margin-half-right   { margin-right: 0.5rem; }

// margin 1rem
.margin              { margin: 0.8rem 1rem; }
.margin-top          { margin-top: 0.8rem; }
.margin-bottom       { margin-bottom: 0.8rem; }
.margin-left         { margin-left: 1rem; }
.margin-right        { margin-right: 1rem; }

// margin 2rem
.margin-two          { margin: 2rem; }
.margin-two-top      { margin-top: 2rem; }
.margin-two-bottom   { margin-bottom: 2rem; }
.margin-two-left     { margin-left: 2rem; }
.margin-two-right    { margin-right: 2rem; }

// margin auto
.margin-left-auto    { margin-left: auto; } // useful for pushing a flex item to the far right

// FLEX ------------------------------------------------------------------------

.flex-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  &.nowrap {
    webkit-flex-wrap: nowrap;
    -ms-flex-nowrap: wrap;
    flex-wrap: nowrap;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

// align-items
.align-center { align-items: center;     }
.align-start  { align-items: flex-start; }
.align-end    { align-items: flex-end;   }

// align-self
.align-self-start { align-self: flex-start; }
.align-self-end   { align-self: flex-end;   }

// justify-content
.justify-start   { justify-content: flex-start; }
.justify-end     { justify-content: flex-end;   }
.justify-center  { justify-content: center;     }
.justify-between { justify-content: space-between; }
.justify-around  { justify-content: space-around; }

.flex-item {
  flex: 1;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;

  &.one-third {
    flex: 1 1 33%;
    @media screen and (max-width: 800px) { flex: 1 1 90%; }
  }
  &.two-thirds {
    flex: 1 1 66%;
    @media screen and (max-width: 800px) { flex: 1 1 90%; }
  }
  &.full {
    flex: 1 1 99%;
    @media screen and (max-width: 800px) { flex: 1 1 90%; }
  }
  &.one-fifth   { flex: 1 1 20%; }
  &.four-fifths { flex: 1 1 80%; }
}

// -----------------------------------------------------------------------------

// width %
.full-w { width: 100%; }
.half-w { width: 50%; }

// height %
.full-h { height: 100%; }
.half-h { height: 50%; }

// no left margin/border
.no-left-margin { margin-left: 0; }
.no-left-border { border-left: none; }

// float right/left
.float-right { float: right; }
.float-left  { float: left;  }

// display
.inline-block { display: inline-block; }

.center-all-directions {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
          align-items: center;
}

.hidden-true {
  opacity: 0;
  transition: all 0.3s ease-out;
  height: 0px;
  overflow-y: hidden;
  display: none;
}

.hidden-false {
  transition: all 0.3s ease-in;
  opacity: 1;
  height: auto;
  overflow-y: inherit;
  display: inherit;
}

.cursor-grab { cursor: grab; }
.cursor-pointer { cursor: pointer; }

.no-wrap { white-space: nowrap; }


// CSS GRID --------------------------------------------------------------------

.grid-container {
  display: grid;
  grid-gap: 30px;

  &.g1-1 { grid-template-columns: 1fr 1fr; }
  &.g1-1-1 { grid-template-columns: repeat(3, 1fr); }
}

.fill-parent { width: 100%; }
