// portal Layer
#portal-root {
  position : absolute;
}


.popper-trigger {
  display: inline-block;
  width: inherit;
  &.disabled {
    opacity: 0.6;
    cursor: default;
    pointer-events: none;
  }
}

// THEMES ----------------------------------------------------------------------
// Passing theme="light" to <BATooltip />
// will provide the 'light-theme' class to .tippy-tooltip.

// Light theme
.tippy-tooltip {
  z-index: var(--z-index-layer-7) !important;
  &.light-theme {
    background: var(--content-bg);
    border: 2px solid var(--line-dark);
    color: var(--text-main);
    .tippy-backdrop { background-color: var(--content-bg); }
  }
}

.tippy-popper[x-placement^='top'] .tippy-tooltip.light-theme .tippy-arrow {
  border-top-color: var(--line-dark);
}
.tippy-popper[x-placement^='bottom'] .tippy-tooltip.light-theme .tippy-arrow {
  border-bottom-color: var(--line-dark);
}
.tippy-popper[x-placement^='left'] .tippy-tooltip.light-theme .tippy-arrow {
  border-left-color: var(--line-dark);
}
.tippy-popper[x-placement^='right'] .tippy-tooltip.light-theme .tippy-arrow {
  border-right-color: var(--line-dark);
}
/* Round arrow */
.tippy-tooltip.light-theme .tippy-roundarrow { fill: var(--line-dark); }
