
:root {
	// ROOT FONT SIZE
	// affects all rem values.
	font-size                   : 16px;

	
	// FONT SIZE CSS Variables
	// Should be the single source of truth on font-sizes.
	--text-size-micro           : 0.7rem;
	--text-line-height-micro    : 0.8rem;
	--button-line-height-micro  : 1rem;
	
	--text-size-small           : 0.8rem;
	--text-line-height-small    : 1.2rem;
	--button-line-height-small  : 1.6rem;
	--form-element-size					: var(--text-size-small);
	--form-element-line-height  : var(--text-line-height-small);
	
	--text-size-small-medium		: 0.9rem;
	
	--text-size-medium          : 1rem;
	--text-line-height-medium   : 1.2rem;
	--button-line-height-medium : 2rem;

	--text-size-large           : 1.5rem;
	--text-line-height-large    : 2rem;
	--button-line-height-large  : 3rem;

	--text-size-jumbo           : 2rem;
	--text-line-height-jumbo    : 2.8rem;
	--button-line-height-jumbo  : 4rem;
}

// Mixins - weights:

:root {
  --font-family: ProximaNovaA-Regular;

  --font-weight: normal;
  --font-medium-font-weight: normal;
  --font-regular-font-weight: normal;
  --font-semibold-font-weight: normal;
  --font-italic-font-weight: normal;
  --font-thin-font-weight: normal;
  --font-italic-font-style: normal;

  --font-medium-font-family: ProximaNovaA-Medium;
  --font-regular-font-family: ProximaNovaA-Regular;
  --font-semibold-font-family: ProximaNovaA-Semibold;
  --font-italic-font-family: ProximaNovaA-RegularIt;
  --font-thin-font-family: ProximaNovaA-Thin;
  --font-tabular-font-family: ProximaNova-Regular;
}

@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono:200,200i,300,300i,400,400i,500,500i|Josefin+Sans:300,300i,400,400i,600|Libre+Baskerville:400,400i,700|Roboto:300,300i,400,400i,500,500i');


@mixin font-medium {
	font-family: var(--font-medium-font-family);
	font-weight: var(--font-medium-font-weight);
	font-style: normal;
}

@mixin font-regular {
	font-family: var(--font-regular-font-family);
	font-weight: var(--font-regular-font-weight);
	font-style: normal;
}

@mixin font-semibold {
	font-family: var(--font-semibold-font-family);
	font-weight: var(--font-semibold-font-weight);
	font-style: normal;
}

@mixin font-italic {
	font-family: var(--font-italic-font-family);
	font-weight: var(--font-italic-font-weight);
	font-style:  var(--font-italic-font-style);
}

@mixin font-thin {
	font-family: var(--font-thin-font-family);
	font-weight: var(--font-thin-font-weight);
	font-style: normal;
}

@mixin tabular {
  font-family: var( --font-tabular-font-family);
  font-variant-numeric: tabular-nums;
}


// sizes...
@mixin text-micro {
  font-size: var(--text-size-micro);
  line-height: var(--text-line-height-micro);
};
@mixin text-small {
	font-size: var(--text-size-small);
	line-height: var(--text-line-height-small);
};
@mixin text-medium {
	font-size: var(--text-size-medium);
	line-height: var(--text-line-height-medium);
};
@mixin text-large {
	font-size: var(--text-size-large);
	line-height: var(--text-line-height-large);
};
@mixin text-jumbo {
	font-size: var(--text-size-jumbo);
	line-height: var(--text-line-height-jumbo);
};

// functional class rules - sizes:
.text-micro.text-micro   { @include text-micro;  }
.text-small.text-small   { @include text-small;  }
.text-medium.text-medium { @include text-medium; }
.text-large.text-large   { @include text-large;  }
.text-jumbo.text-jumbo   { @include text-jumbo;  }

// weights:
.font-medium.font-medium     { @include font-medium;   }
.font-regular.font-regular   { @include font-regular;  }
.font-semibold.font-semibold { @include font-semibold; }
.font-italic.font-italic     { @include font-italic;   }
.font-thin.font-thin         { @include font-thin;     }
.font-tabular.font-tabular   { @include tabular;       }

// Headings / Special text
h1, h2, h3, h4, h5, h6 { color: var(--text-main); }

h1 { @include text-jumbo; }

h2, h3, h4, h5, h6 { @include text-large;}

h1, h2, h3, h4, h5, h6 {
  small {
    color: var(--text-light);
    @include text-large;
  }
}


em { @include font-semibold; }
