@import '../../styles/colors';

$node-height   : 2.5rem;
$max-width     : 330px;

.react-large-tree {
    
  --drop-indicator-color: var(--menu-text-color);
  
  &.drag-allowed-false {
      --drop-indicator-color: var(--hazard);
  }

  color: var(--menu-text-color);
  background: var(--menu-background-color);

  padding: 0;
  max-width: $max-width;
  padding-bottom: 8em;

  .node {
    --node-bg: var(--sub-node-color);

    display: flex;
    padding-top:0.6em;
    padding-bottom:0.6em;
    &.input-node { text-overflow: inherit; } // this addresses a chrome bug, 
                                              // where items with `text-overflow: ellipsis`
                                              // will hide a contained `<input />`
                                              // element
    background: var(--node-bg);

    transform: translate3d(0,0,0);

    padding-right: 2em;

    a { 
      max-width: calc(100% - 30px); // the link cannot grow beyond the size of
                                    // its parent, minus the width of the
                                    // context menu trigger.
                                    // this is important for overflow-wrap 
                                    // behaviour
      color: var(--menu-text-color); 
      display: flex;
      i {
        flex: none;
      }

      span {
        line-height:1.2em;
        max-width: 100%;
        overflow-wrap: break-word;
      }
    }
    
    &.top-level { 
        --node-bg: var(--menu-background-color);
        background: var(--node-bg);
        border-bottom: 1px solid var(--shadow);
    }

    input {
        display: inline-block;
        width: 100%;
        height: $node-height;
        line-height: $node-height;
        outline: none;
        border: none;
        margin: 0;
        font-size: inherit;
        background: inherit;
        color: inherit;
        padding: 0 0.25em;
        
        &::selection {
            color: black;
            background: var(--highlight);
        }
    }

    i.__user-icon {
        margin-right: 0.5em;
        opacity: 0.3;
        align-self: flex-start;
    }

    &.drop-into   { background: var(--confirm);  }
    &.drop-before { 
        box-shadow: 
            inset 0 2px 0  var(--drop-indicator-color),
            0 -3px 0 0 var(--drop-indicator-color)
        ; 
    }
    &.drop-after  { 
        border-color: var(--drop-indicator-color);
        box-shadow: 
            inset 0 -2px 0 var(--drop-indicator-color),
            0 2px 0 0 var(--drop-indicator-color)
        ; 
    }

    &:hover {
        background: var(--menu-background-color-highlight);
        color: var(--menu-text-color);
    }

    &:before {
        content: "";
        display: inline-block;
        color: transparent;
        margin-right: 0.5em;
    }

  }
  
  .input-node {
      padding: 0;
      border: 1px dotted var(--highlight);
  }

  /* 
      the "drop-after" node's box shadow can get hidden underneath the
      following sibling node, so adding an inset shadow to that node as well
      ensure we always get a visual effect perfectly between the two nodes
  */
  .drop-after + * {
      box-shadow: 
          inset 0 2px 0 var(--drop-indicator-color)
      ;
  }
  
  &.dragging-true { cursor: grabbing; }

  &.dragging-true .node {
      a, button {
          pointer-events: none;
      }
  }

  .context-menu {
      background: var(--menu-text-color);
      color: var(--menu-background-color);
      min-height: 4em;
      border-left: var(--menu-background-color) 4px solid;
      padding-left: 0.5em;
  }

  .expandable.expanded {
      &:before { transform: rotate(90deg); }
  }


  .expand-button {
      background: none;
      flex: none;
      border: none;
      color: var(--menu-text-color);
      margin: 0;
      padding: 0;
      margin-left: -1.5em;
      height: 100%;
      width: 1.5em;
      line-height: 1em;

      i {
          display: block;
          transition: transform 0.2s ease-out;
          font-style: normal;
          pointer-events: none;
      }

      &:hover {
          i { transform: rotate(50deg) }
      }

      &:focus {
          box-shadow: none;
          outline: none;
      }

  }

  .expanded .expand-button {
      i { transform: rotate(90deg); }
      &:hover {
          i { transform: rotate(50deg) }
      }
  }

  .example-enter {
      opacity: 0.01;
  }

  .example-enter.example-enter-active {
      opacity: 1;
      transition: opacity 500ms ease-in;
  }

  .example-leave {
      opacity: 1;
  }

  .example-leave.example-leave-active {
      opacity: 0.01;
      transition: opacity 300ms ease-in;
  }

  .context-button {

      position: absolute;
      right: 0;
      top: 0;
      background: none;
      border: none;
      padding: none;
      margin: none;

      margin-right: -2px; 
      height: $node-height;
      z-index: 10;
      transition: all 0.3s ease-in-out;

      &:focus {
          outline: none;
          box-shadow: none;
      }

      &:hover {
          background: var(--menu-text-color);
          circle { fill: var(--menu-background-color);}
      }

  }

  .context-button svg {
      
      fill-rule:evenodd;
      clip-rule:evenodd;
      stroke-linejoin:round;
      stroke-miterlimit:1.41421;

      pointer-events: none;
      
  }

  .context-button circle {
      fill: var(--menu-text-color);
  }

  .context-active .context-button {

      background: var(--menu-text-color);
      circle { fill: var(--menu-background-color);}

      &:hover {
          background: transparent;
          circle { fill: var(--menu-text-color); }
      }

  }
  .search-result-highlight {
      box-shadow: 0 3px 0 0 var(--highlight);
  }

//     &.drag-allowed-false {
// 
//         .node {
//             &.drop-into { background: var(--hazard); }
//             &.drop-before { box-shadow: inset 0 4px 0 var(--hazard); }
//             &.drop-after { box-shadow: inset 0 -4px 0 var(--hazard); }
//         }
//     }

  .slide-in-after ~ .sub-level {
      transform: translate3d(-1350px, 0, 0);
      animation: scoot-in;
      animation-duration: 0.2s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
  }

  .slide-in-before ~ .sub-level {
      transform: none;
      animation: none;
  }

  .node.active-drag-node,
  .node.selected {
      background: var(--highlight);
      box-shadow: inset 0 0 4px var(--menu-text-color);
      animation-duration: 0s;
  }
  
  .node.just-dragged {
      transform: translate3d(0,0,0);
      animation-name: fade-from-highlight !important;
      animation-duration: 1s !important;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      animation-timing-function: ease-out;
  }
  
  // cursors
  .node[draggable=true] {
      cursor: grab;
      cursor: -webkit-grab;
      cursor: -moz-grab;
      a, button {
          cursor: pointer;
      }
  }
  
  .node[draggable=true]:active {
      cursor: grabbing;
      cursor: -webkit-grabbing;
      cursor: -moz-grabbing;
      a, button {
          cursor: inherit;
      }
  }
  
  &.selection-toggle .node,
  &.selection-range .node,
  &.selection-toggle .node[draggable=true],
  &.selection-range .node[draggable=true] {
      cursor: cell;
  }
  
  .node.highlight i.__user-icon {
      opacity: 1 !important;
      color: var(--highlight);
  }
}

.drag-status {
    background: var(--highlight);
    color: var(--white);
    animation: scoot-in;
    animation-delay: 10ms;
    animation-fill-mode: forwards;
    animation-duration: 0.2s;
    padding: 0.25rem;
    display: inline-block;
    border-radius: 3px;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: var(--text-size-small);
}

@keyframes scoot-in {
    0%   { transform: translate3d(-1350px, 0, 0); }
    100% { transform: translate3d(0,0,0); }
}

@keyframes scoot-out {
    0%   { transform: translate3d(0,0,0); }
    100% { transform: translate3d(-1350px, 0, 0); }
}

@keyframes fade-from-highlight {
    0% {
        background: var(--highlight);
        box-shadow: inset 0 0 4px var(--menu-text-color);
    }

    100% {
        background: var(--node-bg);
        box-shadow: none;
    }
}

