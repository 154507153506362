.Accordion {
  border-radius: 0px;
  border: none;
  
  &PanelHeader {
    display: flex;
    border-bottom: 1px solid var(--line-light-grey);
    background: var(--white);
    color: var(--black);
    
    &IconRight {
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  }
}