// imported here means they go in the bundle!
@import "typography";
@import "z-index";
@import "colors";
@import "functional";
@import "tooltip";
@import "typography";
@import "forms";

* {
  box-sizing: border-box;
}

body {
  font-family: arial; // backup font
  @include font-regular; // main font
  @include text-medium;
  background: var(--bg);
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  @media screen and (-webkit-min-device-pixel-ratio: 2) {
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

#page-content-wrapper {
  padding: 0;
  min-height: 90vh;
  width: 100%;
  // NOTE - the following 2 rules are needed
  // for modals/popovers/tooltips to work.
  position: absolute;
  top: 45px;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

input,
textarea,
select,
button {
  font-family: arial;
  @include font-regular;
  color: inherit;
  font-size: inherit;
}

hr {
  border: 1px solid var(--line);
}
